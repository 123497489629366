import React from 'react';
import Button from '@material-ui/core/Button';

import Tabs, { Tab } from '../components/Tabs';
import { colors } from '../theme';
import Report from "./Report";
import Explore from './Explore';
import NumericKey from './ComponentsDemo';
import { Group } from '../components/Group';

const tabs: Array<Tab> = [
  {key: 'report', label: 'Report', component: <Report />},
  {key: 'explore', label: 'Explore', component: <Explore />},
  {key: 'component demo', label: 'Component Demo', component: <NumericKey />}
];



export default function Demo() {
  const [selectedTabKey, onChangeTab] = React.useState<string | number>('report');

  return (
    <div style={{padding: 16}}>
      <Tabs tabs={tabs} selectedTabKey={selectedTabKey} onChange={onChangeTab} />
    </div>
  )
}