import React from 'react';
import TabLayout from '../components/TabLayout';



export default function Report() {

  return (
    <TabLayout>
      Report
    </TabLayout>
  )
}