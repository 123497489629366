import React from 'react';
import TabLayout from '../components/TabLayout';



export default function Explore() {

  return (
    <TabLayout>
      Explore
    </TabLayout>
  )
}