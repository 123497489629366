import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

import { muiTheme as theme } from './theme';

import Demo from './pages/Demo';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Demo />
    </ThemeProvider>
  );
}

export default App;
