import React from 'react';
import { colors } from '../theme';

export interface Tab {
  label: string,
  key: number | string,
  component: any,
}

export interface TabsProps {
  tabs: Array<Tab>,
  selectedTabKey: number | string,
  onChange: (key: number | string) => void,
}

const TAB_STYLE: React.CSSProperties = {
  border: `1px solid ${colors.primary}`,
  borderRadius: 4,
  marginRight: 4,
  padding: '0 4px',
  background: 'white',
  color: colors.primary,
};

const SELECTED_TAB_STYLE: React.CSSProperties = {
  ...TAB_STYLE,
  background: colors.primary,
  color: 'white',
};


export default function Tabs(props: TabsProps) {
  const { tabs, selectedTabKey, onChange } = props;

  const getStyle = (key: string | number) => {
    if (selectedTabKey === key) return SELECTED_TAB_STYLE;
    return TAB_STYLE;
  }

  const displayComponent = (tab: Tab) => {
    if (selectedTabKey === tab.key) return tab.component;
    return null;
  }


  return (
    <>
      <div style={{display: 'flex'}}>
        {
          tabs.map((tab) => (
            <div key={tab.key} style={getStyle(tab.key)} onClick={() => onChange(tab.key)}>{tab.label}</div>
          ))
        }
      </div>
      <div style={{paddingTop: '16px'}}>
        {
          tabs.map((tab) => displayComponent(tab))
        }
      </div>

    </>
  );
}
