import React from 'react';
import Button from "@material-ui/core/Button";
import {colors} from "../theme";
import {Group} from "../components/Group";
import TabLayout from "../components/TabLayout";



export default function ComponentsDemo() {

  return (
    <TabLayout>
      <h2>Components demo</h2>

      <Group title="Primary buttons">
        <Button color="primary" size="small" variant="contained">Primary button</Button>
        {' '}
        <Button color="primary" size="small" variant="outlined">Outlined</Button>
      </Group>
      <Group title="Secondary buttons">
        <Button color="secondary" size="small" variant="contained">Secondary button</Button>
        {' '}
        <Button color="secondary" size="small" variant="outlined">Outlined</Button>
      </Group>
    </TabLayout>
  )
}